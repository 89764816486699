
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import MapLocation from '@/components/mapLocation/Index.vue'
import UploadFile from '@/components/uploadFile/Index.vue'
import { AddInfo, LocationData } from '@/types/intelligentMachine'

@Component({
  name: 'IntelligentMachineAdd',
  components: { MapLocation, UploadFile }
})
export default class extends Vue {
  private formData: AddInfo = {
    projectId: '',
    smartMachineName: '',
    storageDate: '',
    description: '',
    resourcesLocationList: [],
    resourceList: []
  }

  private resourcesLocationList: LocationData[] | null = []
  private submitDisabled = false

  private rulesForm = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: 'change' }
    ],
    description: [{ required: true, message: '请填写描述', trigger: 'blur' }],
    smartMachineName: [
      { required: true, message: '请填写机械名称', trigger: 'blur' }
    ],
    storageDate: [
      { required: true, message: '请选择入库日期', trigger: 'change' }
    ],
    resourcesLocationList: [
      { required: true, message: '请选择坐标位置', trigger: 'change' }
    ],
    resourceList: [{ required: true, message: '请上传图片', trigger: 'change' }]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    const id = this.$route.params.id
    if (id) {
      // 编辑
      this.getDetail(id)
    }
  }

  // 经纬度
  locationChange (arr: LocationData[]) {
    this.formData.resourcesLocationList = arr
  }

  confirmAdd () {
    ;(this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.submitDisabled = true
        const urls = this.$route.params.id ? this.$apis.intelligentMachine.updateSmartMachine : this.$apis.intelligentMachine.insertSmartMachine
        this.$axios.post(urls, this.formData).then(() => {
          const text = this.$route.params.id ? '编辑成功' : '新增成功'
          this.$message.success(text)

          this.$router.push({ path: '/intelligentMachine' })
        }).finally(() => {
          this.submitDisabled = false
        })
      }
    })
  }

  imgRemove (index: number) {
    this.formData.resourceList.splice(index, 1)
  }

  uploadSuccess (file: FileInfo) {
    this.formData.resourceList.push(file)
  }

  getDetail (id: string) {
    this.$axios
      .get(this.$apis.intelligentMachine.selectSmartMachineBySmartMachineId, {
        smartMachineId: id
      })
      .then((res) => {
        this.resourcesLocationList = res.resourcesLocationList
        console.log(res)
        this.formData = res
      })
  }
}
